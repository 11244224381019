
.scroller {
    position: absolute;
    bottom: 20px;
    right: 40px;
    background-color: #4e8382;
    color: white;
    width: 50px;
    height: 50px;
    border-radius: 30px;
    text-align: center;
    padding-top: 4px;
    font-size: 21pt;
    font-weight: 900;  /* Max boldness for the arrow */
}
.scroller a, .scroller a:hover {
    color: white;
    text-decoration: none;
}
.scroller:hover {
    width: 180px;
}
.scroller .info {
    font-size: 12pt;
    display: none;
}
.scroller:hover .info {
    font-size: 10pt;
    font-weight: normal;
    display: inline-block;
}
