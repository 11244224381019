
main[data-v-7ba5bd90] {
    flex: 1 0 auto;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}
.root[data-v-7ba5bd90] {
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.aristotle-logo[data-v-7ba5bd90] {
    margin-top: 5px;
}
.browser-message[data-v-7ba5bd90] {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
