














































/* Dont grow or shrink header */
.navbar-custom[data-v-61dd7a3d] {
    flex: 0 0 auto;
}

/* Custom styling for navbar to make it aristotle green */
.navbar-custom[data-v-61dd7a3d] {
    background-color: #356a69;
}

/* change the brand and text color */
.navbar-custom .navbar-brand[data-v-61dd7a3d],
.navbar-custom .navbar-text[data-v-61dd7a3d] {
    color: white;
}

/* change the link color */
.navbar-custom .navbar-nav .nav-link[data-v-61dd7a3d] {
    color: white;
}

/* change the color of active or hovered links */
.navbar-custom .nav-item.active .nav-link[data-v-61dd7a3d],
.navbar-custom .nav-item:hover .nav-link[data-v-61dd7a3d] {
    color: white;
}

/* for dropdown only - change the color of droodown */
.navbar-custom .dropdown-menu[data-v-61dd7a3d] {
    background-color: white;
}
.navbar-custom .dropdown-item[data-v-61dd7a3d] {
    color: black;
}
.navbar-custom .dropdown-item[data-v-61dd7a3d]:hover,
.navbar-custom .dropdown-item[data-v-61dd7a3d]:focus {
    color: black;
    background-color: white;
}
