
.placeholder-bar-graph[data-v-679052f6] {
    width: 70%;
    height: 300px;
    background-color: gray;
}
.placeholder-bar-graph p[data-v-679052f6] {
    text-align: center;
    padding-top: 100px;
}
