
.footer-custom[data-v-40ab164b] {
    /* Dont grow or shrink footer */
    flex: 0 0 auto;
    width: 100%;
    background-color: #356a69;
    padding-top: 10px;
    padding-bottom: 5px;
}
footer .promo[data-v-40ab164b] {
    display: table;
    margin: auto;
}
footer .promo > div[data-v-40ab164b] {
    display:table-cell;
    vertical-align: middle;
}
footer .promo p[data-v-40ab164b] {
    margin: 0.1em;
}
