




































































































































































/* pull all this out as its used in both graphs */
/* not scoped as we need to target the labels */
.graph-title {
    font-size: 110%;
    font-weight: 600;
    text-align: center;
    margin-left: 60px;
}
.graph-description {
    font-size: 90%;
    margin-left: 10%;
    margin-right: 10%;
}
.option-selector {
    margin-top:5px;
}
.option-selector label {
    font-size: 90%;
}
.well-help {
    margin:0 15px;
    font-size: 90%;
}
a[data-aristotle-concept-id] {
    color: #356a69;
    font-weight: 600;
    border-bottom: 1px dashed #356a69;
}
a[data-aristotle-concept-id]:hover {
    color: #356a69;
    border-bottom: 1px solid #356a69;
    text-decoration: none;
}

