
.form-block[data-v-4081900c] {
    display: block;
    margin: 10px 20px;
}
input[type=radio][data-v-4081900c] {
    transform: scale(1.25);
}
.help[data-v-4081900c] {
    font-size: 90%;
}
