










































































































































































































































































































































/*
 * Can't use scoped css here since dynamically added elements wont have the attribute
 * So we are using the metadata-display class for scoping the rules
 */

/* Set text size */
svg.metadata-display text {
  font-size: 11px;
  font-weight: 300;
}

/* Make title larger and bolder */
svg.metadata-display tspan.title {
    font-size: 12px;
    font-weight: 500;
}
svg.metadata-display g.headings text {
    font-size: 16px;
    font-weight: 500;
}
svg.metadata-display a:link, svg.metadata-display a:visited {
    cursor: pointer;
}
svg.metadata-display a:hover, svg.metadata-display a:active {
    text-decoration: underline;
}

/* Syle node boxes */
svg.metadata-display .node rect {
  stroke: #999;
  fill: #fff;
  stroke-width: 1.5px;
}

/* Override for selected nodes */
svg.metadata-display .selected rect {
    fill: lightgreen;
}

/* Style edges */
svg.metadata-display .edgePath path {
  stroke: #333;
  stroke-width: 1.5px;
}
